import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-calidad',
  templateUrl: './calidad.component.html'
})
export class CalidadComponent implements OnInit {
  vistaApartado:boolean = false;
  constructor(private _datosService:DatosService) { }

  ngOnInit() {
    this.vistaApartado = false;
    this.sacaApartado();
    this._datosService.updateTitle('Calidad Proinbel');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

}
