import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  active: boolean = false;
  fotos:any = ['slide0.jpg', 'slide1.jpg', 'slide2.jpg'];

  fotoActual: any = '';
  espera: number = 3000;
  indexActual:number;
  interval;

  vistaApartado:boolean = false;

  constructor(public _datosService:DatosService) {

  }

  ngOnInit() {
    this.indexActual = 0;
    this.vistaApartado = false;
    this.sacaApartado();
    this._datosService.updateTitle('Proinbel Granallados y Pintura Industrial');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    setTimeout(() => { this.active = true; this.getNext(); }, 500);
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

  //FUNCIONES DE SLIDE DE HOME
getNext() {
  this.interval = setInterval (() => {
      if(this.indexActual == this.fotos.length - 1) {
        this.indexActual = 0;
      }
      else {
        this.indexActual++;
      }
  }, this.espera);
}

}
