import { Component,  OnInit, ViewChild, Input } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { Router, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart } from '@angular/router';
import { DatosService } from '../app/services/datos.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';

import { Title ,Meta} from '@angular/platform-browser';

import { fadeAnimation } from './animations';
import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';


//Por default dejamos el español
let defaultLanguage = "es";
//Y como adicional el resto
const additionalLanguages = ["en"];
//Combinamos las traducciones disponibles al default.
const languages: string[] = [defaultLanguage].concat(additionalLanguages);


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./../assets/css/main.min.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
   fadeAnimation
   // animation triggers go here
 ]
})
export class AppComponent {
  title = 'Proinbel Granallados y Pintura Industrial';

  constructor( private _datosService:DatosService, private translate: TranslateService, private router: Router, private meta:Meta) {
/*
  this.meta.addTag({ name: 'title', content: 'Proinbel Granallados y Pintura Industrial'});
  this.meta.addTag({ name: 'description', content: 'Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial.'});
  this.meta.addTag({ name: 'author', content: 'Euphorbia Comunicación'});
  this.meta.addTag({ name: 'keywords', content: 'Proinbel, granallados, pintura industrial, protección anticorrosiva, acero inoxidable, carbono, galvanizado, sector naval, sector alimentario, sector automoción, calderería, decoración, mobiliario urbano'});
*/
 }

 ngOnInit(): void {
      //Asignamos el lenguaje predefinido y los disponibles
      this.translate.setDefaultLang(defaultLanguage);
      this.translate.addLangs(additionalLanguages);
      //Nos basamos en el lenguaje del navegador para cambiar el lenguaje
      let initLang = this.translate.getBrowserLang();
      if (languages.indexOf(initLang) === -1) {
          initLang = defaultLanguage;

      }
      //En base a lo anterior asignamos el lenguaje a usar
      this.translate.use(initLang);

      this.router.events.subscribe((event: Event) => {
        switch(true) {
          case event instanceof NavigationStart: {
            break;
          }
          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError: {

            window.scrollTo(0, 0);
            break;
          }
          default: {
            break;
          }
        }
      });
 }
}
