import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DatosService } from '../../services/datos.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-servicio',
  templateUrl: './servicio.component.html'
})
export class ServicioComponent implements OnInit {
  vistaApartado:boolean = false;
  padre:string = "";
  servicio:string = "";
  submenu: any = [];

  constructor(private _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.padre = this.router.routerState.snapshot.root.firstChild.url[0].path;
        this.servicio = this.router.routerState.snapshot.root.firstChild.url[1].path;
        this.submenu = [];

        if(this.padre == 'granallados') {
          this.submenu = this._datosService.subMenuGranallados;
        }
        if(this.padre == 'obras') {
          this.submenu = this._datosService.subMenuObras;
        }
      }
    });



  }

  ngOnInit() {
    this.vistaApartado = false;
    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

}
