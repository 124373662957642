import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule,
  MatExpansionModule, MatDatepickerModule, MatInputModule, MatTabsModule,
  MatTooltipModule, MatFormFieldModule, MatMenuModule, MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Http, HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import { LocationStrategy, HashLocationStrategy, DatePipe, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

// Rutas
import { app_routing } from './app.routes';

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { EmpresaComponent } from './components/empresa/empresa.component';
import { GranalladosComponent } from './components/granallados/granallados.component';
import { ObrasComponent } from './components/obras/obras.component';
import { ActualidadComponent } from './components/actualidad/actualidad.component';
import { ReferenciasComponent } from './components/referencias/referencias.component';
import { MarcaComponent } from './components/marca/marca.component';
import { NormativasComponent } from './components/normativas/normativas.component';
import { ServicioComponent } from './components/servicio/servicio.component';
import { DepartamentoTecnicoComponent } from './components/departamento-tecnico/departamento-tecnico.component';
import { CalidadComponent } from './components/calidad/calidad.component';
import { DinamicaComponent } from './components/dinamica/dinamica.component';
import { CookiesComponent } from './components/cookies/cookies.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    EmpresaComponent,
    GranalladosComponent,
    ObrasComponent,
    ActualidadComponent,
    ReferenciasComponent,
    MarcaComponent,
    NormativasComponent,
    ServicioComponent,
    DepartamentoTecnicoComponent,
    CalidadComponent,
    DinamicaComponent,
    CookiesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // new modules added here
    [MatButtonModule, MatCheckboxModule, MatToolbarModule, MatCardModule, MatMenuModule,
    MatExpansionModule, MatInputModule, MatTabsModule, MatTooltipModule, MatFormFieldModule,
    MatSnackBarModule, MatSelectModule, MatRadioModule, MatListModule],
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    app_routing,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: function(http: Http) {return new TranslateStaticLoader(http, '/assets/i18n', '.json')},
      deps: [Http]
    })
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})

export class AppModule{ }
