import { Routes, RouterModule } from '@angular/router';

import { HomeComponent} from '../app/components/home/home.component';
import { EmpresaComponent} from '../app/components/empresa/empresa.component';
import { GranalladosComponent } from '../app/components/granallados/granallados.component';
import { ObrasComponent } from '../app/components/obras/obras.component';
import { ServicioComponent } from '../app/components/servicio/servicio.component';
import { NormativasComponent } from '../app/components/normativas/normativas.component';
import { ActualidadComponent } from '../app/components/actualidad/actualidad.component';
import { ReferenciasComponent } from '../app/components/referencias/referencias.component';
import { DepartamentoTecnicoComponent} from '../app/components/departamento-tecnico/departamento-tecnico.component'
import { CalidadComponent} from '../app/components/calidad/calidad.component';
//import { ContactoComponent} from '../app/components/contacto/contacto.component';
import { DinamicaComponent} from '../app/components/dinamica/dinamica.component';


const app_routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'empresa', component: EmpresaComponent },
  { path: 'departamento-tecnico', component: DepartamentoTecnicoComponent },
  { path: 'talleres', component: GranalladosComponent },
  { path: 'talleres/:id', component: ServicioComponent },
  { path: 'obras', component: ObrasComponent },
  { path: 'obras/:id', component: ServicioComponent },
  { path: 'actualidad', component: ActualidadComponent },
  { path: 'actualidad/:id', component: ActualidadComponent },
  { path: 'normativas', component: ActualidadComponent },
  { path: 'calidad', component: CalidadComponent },
  { path: 'referencias', component: ReferenciasComponent },
  { path: 'aviso-legal', component: DinamicaComponent },
  { path: 'politica-de-privacidad', component: DinamicaComponent },
  { path: 'politica-de-cookies', component: DinamicaComponent },

  { path: '**', pathMatch: 'full', redirectTo: 'home' }
];


export const app_routing = RouterModule.forRoot(app_routes);
