import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html'
})
export class EmpresaComponent implements OnInit {
  vistaApartado:boolean = false;
  active: boolean = false;
  fotos:any = ['slide0.jpg', 'slide1.jpg', 'slide2.jpg'];

  fotoActual: any = '';
  espera: number = 3000;
  indexActual:number;
  interval;

  constructor(public _datosService:DatosService) { }

  ngOnInit() {
    this.vistaApartado = false;
    this.sacaApartado();
    this._datosService.updateTitle('Empresa Proinbel');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    clearInterval(this.interval);
    this.indexActual = 0;
    setTimeout(() => { this.active = true; this.getNext(); }, 500);
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

  //FUNCIONES DE SLIDE DE HOME
  getNext() {
    this.interval = setInterval (() => {
        if(this.indexActual == this.fotos.length - 1) {
          this.indexActual = 0;
        }
        else {
          this.indexActual++;
        }
    }, this.espera);
  }

}
