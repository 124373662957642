import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DatosService } from '../../services/datos.service';
import { Observable } from 'rxjs/Observable';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import { Directive, ElementRef,Renderer} from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-actualidad',
  templateUrl: './actualidad.component.html'
})
export class ActualidadComponent implements OnInit {
  vistaApartado:boolean = false;
  listadoNoticias:any = [];
  noticiasFiltradas:any = [];
  listadoCategorias:any = [];

  private noticias: any[];
  private noticia: any[];
  isNoticia: boolean = false;

    constructor(private _datosService:DatosService, private activatedRoute:ActivatedRoute, private router: Router, private meta:Meta) { }

  ngOnInit() {
    this.activatedRoute.url.subscribe( url=> {
      let ruta = this.activatedRoute.snapshot.url[0].path;

      this.sacaApartado();
      this.vistaApartado = false;
      this.isNoticia = false;

      this._datosService.getCategorias().subscribe(datos => {
        this.listadoCategorias = datos;
        console.log(this.listadoCategorias);

        this._datosService.getNoticias().subscribe(datos=>{
          this.listadoNoticias = datos;
          this.listadoNoticias = this.listadoNoticias.filter((noticia)=> noticia.publicado);
          console.log(this.listadoNoticias);


          if(this._datosService.categoriaBlog != '') {
            this.noticiasFiltradas = this.listadoNoticias.filter((noticia)=> noticia.category_id == this._datosService.categoriaBlog['id'] );
          }
          else {
            this.noticiasFiltradas = this.listadoNoticias;
          }

          //ORDENO POR POSITION
            this.noticiasFiltradas.sort((val1, val2)=> {return new Date(val2.publishing_date).getTime() - new Date(val1.publishing_date).getTime()});


          //SI ESTAMOS EN UNA NOTICIA...
          if(this.activatedRoute.snapshot.url.length > 1) {
            let rutaNoticia = this.activatedRoute.snapshot.url[1].path;
            this._datosService.getNoticia(rutaNoticia).subscribe(datos=> {
              this.noticia = datos;
              this.noticia['miCategoria'] = this.getCategoria(this.noticia['category_id']);
              console.log(this.noticia);
              this.isNoticia = true;

              this._datosService.updateTitle(this.noticia['meta_titulo'] + ' | Proinbel');
              this._datosService.updateOgUrl(rutaNoticia);
              //Updating Description tag dynamically with title
              this._datosService.updateDescription(this.noticia['meta_descripcion']);
              //filtramos por su categoria y eliminamos la actual
              //this.noticiasFiltradas = this.noticias.filter((noticia)=> noticia.category_id == this.noticia['category_id'] );
              //this.noticiasFiltradas = this.noticias.filter((noticia)=> noticia.id != this.noticia['id'] );
            })
          }
          else
          {
            this._datosService.updateTitle('Actualidad Proinbel');
            this._datosService.updateOgUrl('');
            //Updating Description tag dynamically with title
            this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

          }
        })
      })
    });

    this.vistaApartado = false;
    this.sacaApartado();
  }

  filtraCategoria(categoria){
    this._datosService.categoriaBlog = categoria;
    console.log(categoria);
    if(this._datosService.categoriaBlog != '') {
      this.noticiasFiltradas = this.listadoNoticias.filter((noticia)=> noticia.category_id == this._datosService.categoriaBlog['id'] );
    }
    else {
      this.noticiasFiltradas = this.listadoNoticias;
    }
  }
  getCategoria(id) {
    let miCategoria = this.listadoCategorias.findIndex((categoria) => categoria.id == id );
    return (this.listadoCategorias[miCategoria]['nombre']);
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

}
