import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-granallados',
  templateUrl: './granallados.component.html'
})
export class GranalladosComponent implements OnInit {
  vistaApartado:boolean = false;
  active: boolean = false;
  fotos:any = ['slide1.jpg', 'slide2.jpg', 'slide3.jpg', 'slide4.jpg', 'slide5.jpg', 'slide6.jpg', 'slide7.jpg', 'slide8.jpg', 'slide9.jpg', 'slide10.jpg', 'slide11.jpg', 'slide12.jpg', 'slide13.jpg', 'slide14.jpg'];
  fotosTitulos:any = ['Cabina granallado', 'Cesta Tec', 'Conductum interior', 'Cox', 'Cox2', 'Descontaminación inox anterior', 'Descontaminación inxo después', 'DTA', 'Filtros', "Foto taller", "Quitapiedras", "Radiador", "Tec", "Válvulas"];

  fotoActual: any = '';
  espera: number = 3000;
  indexActual:number;
  interval;

  constructor(private _datosService:DatosService) { }

  ngOnInit() {
    this.vistaApartado = false;
    this.sacaApartado();
    this._datosService.updateTitle('Talleres Proinbel');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    clearInterval(this.interval);
    this.indexActual = 0;
    setTimeout(() => { this.active = true; this.getNext(); }, 500);
  }

  //FUNCIONES DE SLIDE DE HOME
  getNext() {
    this.interval = setInterval (() => {
        if(this.indexActual == this.fotos.length - 1) {
          this.indexActual = 0;
        }
        else {
          this.indexActual++;
        }
    }, this.espera);
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

}
