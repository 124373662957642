import { Component, OnInit } from '@angular/core';
import { DatosService } from '../../services/datos.service';

@Component({
  selector: 'app-departamento-tecnico',
  templateUrl: './departamento-tecnico.component.html'
})
export class DepartamentoTecnicoComponent implements OnInit {
  vistaApartado:boolean = false;
  constructor(private _datosService:DatosService) { }

  ngOnInit() {
    this.vistaApartado = false;
    this._datosService.updateTitle('Departamento Técnico Proinbel');
    this._datosService.updateOgUrl('');
    //Updating Description tag dynamically with title
    this._datosService.updateDescription('Proinbel dedicados a la protección anticorrosiva y a la pintura en el ámbito industrial');

    this.sacaApartado();
  }

  sacaApartado() {
    setTimeout(() => {
      this.vistaApartado = true;
    }, 1000);
  }

}
