import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'ng2-translate';
import { Subscription } from 'rxjs/Subscription';
import { DatosService } from '../../services/datos.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  currentLang:any = '';
  //menu: any = ["home","empresa", "talleres", "obras", "departamento-tecnico","calidad"];
  menu: any = ["home","empresa", "talleres", "obras", "departamento-tecnico","calidad", "actualidad"];
  apartado: string = '';
  submenu: any = [];


  constructor(private _datosService:DatosService,
    private translate: TranslateService, private activatedRoute:ActivatedRoute, private router: Router) {
      this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        this.apartado = this.router.routerState.snapshot.root.firstChild.url[0].path;
        this.submenu = [];

        if(this.apartado == 'talleres') {
          //this.submenu = this._datosService.subMenuGranallados;
        }
        if(this.apartado == 'obras') {
          //this.submenu = this._datosService.subMenuObras;
        }
      }
      });
    }

  ngOnInit(): void {


  }

  getLang() {
    if(this.currentLang) {
      return this.currentLang;
    }
    else{
      return this.translate.getBrowserLang();
    }
  }
}
